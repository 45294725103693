import { useState, useEffect } from 'react';
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import api from '../../axiosConfig';
import CarouselComponent from '../CarouselComponent';
import './Home.scss';

function Home() {
  const [aboutUs, setAboutUs] = useState({ title: '', content: '' });
  const [prestations, setPrestations] = useState([]);

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const response = await api.get('/about-us');
        if (response.data.length > 0) {
          setAboutUs(response.data[0]);
        }
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    const fetchPrestations = async () => {
      try {
        const response = await api.get('/prestations');
        setPrestations(response.data);
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    fetchAboutUs();
    fetchPrestations();
  }, []);

  return (
    <div className="home">
      <Container sx={{ marginTop: 4 }}>
        <Box
          id="about"
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            padding: 3,
            marginBottom: 4,
            width: '100%',
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{textAlign:"center"}}>
            {aboutUs.title}
          </Typography>
          <Typography variant="body1" component="p">
            {aboutUs.content}
          </Typography>
        </Box>
        <CarouselComponent sx={{ marginBottom: 4 }} />
        <Box
  id="prestations"
  sx={{
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 3,
    padding: 3,
    marginTop: 4, // Ajouté ici
    marginBottom: 4,
    width: '100%',
  }}
>
          <Typography variant="h5" component="h2" gutterBottom sx={{textAlign:"center"}}>
              Prestations
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {prestations.map((prestation) => (
                <Grid item key={prestation.id} xs={12} sm={6} md={4}>
                <Card
                    component={Link}
                    to={`/prestation/${prestation.id}`}
                    sx={{
                      textDecoration: 'none',
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                  >
                   <Box sx={{ border: '1px solid #ddd', borderRadius: 2 }}>                 
                  <CardMedia
                      component="img"
                      height="140"
                      image={`https://admin.jcpcreation.com/uploads/images/${prestation.image}`}
                      alt={prestation.name}
                    />
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {prestation.name}
                      </Typography>
                    </CardContent>
                  </Box> 
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
      </Container>
    </div>
  );
}

export default Home;