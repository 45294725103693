import React, { useState, useEffect } from 'react';
import api from '../../axiosConfig';
import { Link } from 'react-router-dom';
import { Button, Box, Stack, Grid, useMediaQuery, useTheme } from '@mui/material';

function CategoriesList() {
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    api
      .get('/categories')
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      {isMobile ? (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Button
              variant="contained"
              component={Link}
              to="/realisations"
              sx={{
                backgroundColor: '#4a8b6c',
                '&:hover': { backgroundColor: '#4a8b6c' },
              }}
            >
              Toutes les réalisations
            </Button>
          </Grid>
          {categories.map((category) => (
            <Grid item xs={6} key={category.id}>
              <Button
                variant="contained"
                component={Link}
                to={`/realisations/category/${category.id}`}
                sx={{
                  backgroundColor: '#4a8b6c',
                  '&:hover': { backgroundColor: '#4a8b6c' },
                }}
              >
                {category.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="contained"
            component={Link}
            to="/realisations"
            sx={{
              backgroundColor: '#4a8b6c',
              '&:hover': { backgroundColor: '#4a8b6c' },
            }}
          >
            Toutes les réalisations
          </Button>
          {categories.map((category) => (
            <Button
              key={category.id}
              variant="contained"
              component={Link}
              to={`/realisations/category/${category.id}`}
              sx={{
                backgroundColor: '#4a8b6c',
                '&:hover': { backgroundColor: '#4a8b6c' },
              }}
            >
              {category.name}
            </Button>
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default CategoriesList;
