import axios from 'axios';

const api = axios.create({
  baseURL: 'https://admin.jcpcreation.com/api', // Votre URL de base
  headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*',
  },
});

export default api;