import React from 'react';
import { useParams } from 'react-router-dom';
import CategoriesList from './CategoriesList';
import RealisationsList from './RealisationsList';

function RealisationsPage() {
  const { categoryId } = useParams();

  return (
    <div>
      <CategoriesList />
      <RealisationsList categoryId={categoryId} />
    </div>
  );
}

export default RealisationsPage;
