import { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import api from '../axiosConfig';
import './CarouselComponent.scss';

function CarouselComponent() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await api.get('/sliders');
        if (response.status !== 304) {
          setSlides(response.data);
        }
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    fetchSlides();
  }, []);
  return (
    <div className="carousel-container">
      <Carousel indicators={false} interval={2500}>
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={slide.imageUrl}
              alt={`Slide ${index}`}
            />
            <Carousel.Caption>
              <h3>{slide.title}</h3>
              <p>{slide.text}</p>
              {slide.textButton && (
                <Button className="btn-custom" href="/contact">
                  {slide.textButton}
                </Button>
              )}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
