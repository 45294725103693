/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import api from '../axiosConfig';
import { Menu, MenuItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function PrestationsMenu() {
  const [prestations, setPrestations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    api
      .get('/prestations')
      .then((response) => {
        setPrestations(response.data);
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button color="inherit" onClick={handleClick}>
        Prestations
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {prestations.map((prestation) => (
          <MenuItem
            key={prestation.id}
            component={Link}
            to={`/prestation/${prestation.id}`}
            onClick={handleClose}
          >
            {prestation.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default PrestationsMenu;
