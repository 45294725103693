import React, { useState, useEffect } from 'react';
import api from '../../axiosConfig';
import {
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  useTheme,
  useMediaQuery,
} from '@mui/material';

function RealisationsList({ categoryId }) {
  const [realisations, setRealisations] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let url = '/realisations';
    if (categoryId) {
      url += `?category_id=${categoryId}`;
    }
    api
      .get(url)
      .then((response) => {
        setRealisations(response.data);
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  }, [categoryId]);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {realisations.length === 0 ? (
        <Typography variant="h6" component="div" color="text.secondary">
          Il n'y a pas de photos dans cette catégorie.
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {realisations.map((realisation) => (
            <Grid item xs={12} sm={6} md={4} key={realisation.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={realisation.imageUrl}
                  alt={realisation.title}
                  onClick={() => handleOpen(realisation.imageUrl)}
                  sx={{ cursor: 'pointer' }}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {realisation.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {realisation.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '80%' : '50%',
          maxWidth: '600px',  // Limite la largeur maximale
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          maxHeight: '80%',  // Limite la hauteur maximale
          overflow: 'hidden',
        }}
      >
        <Box
          component="img"
          src={selectedImage}
          alt="Realisation"
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: 'calc(80vh - 64px)',  // Ajuste l'image à l'intérieur de la modale
            objectFit: 'contain',
          }}
        />
      </Box>
    </Modal>
    </Box>
  );
}

export default RealisationsList;
