import { Box, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import dotenv from 'dotenv';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import RealisationsPage from './components/Realisation/Realisations';
import ContactPage from './components/Contact/Contact';
import ContactFloatingButton from './components/ContactFloatingButton';
import Prestation from './components/Prestation/Prestation'; // Importez le composant Prestation
import './App.scss';

function App() {
  dotenv.config();
  return (
    <Router>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#8ca396',
        }}
      >
        <CssBaseline />
        <Header />
        <Box component="main" sx={{ flex: 1, p: 3 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/realisations" element={<RealisationsPage />} />
            <Route
              path="/realisations/category/:categoryId"
              element={<RealisationsPage />}
            />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/prestation/:id" element={<Prestation />} />
          </Routes>
        </Box>
        <Footer />
        <ContactFloatingButton />
      </Box>
    </Router>
  );
}

export default App;
