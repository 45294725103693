import { useState } from 'react';
import { Fab, Menu, MenuItem, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

function ContactFloatingButton() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="contact"
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          backgroundColor: '#8ca396',
          '&:hover': {
            backgroundColor: '#7a8b7a',
          },
          zIndex: 1000, // Assurez-vous que le bouton reste au-dessus des autres éléments
        }}
      >
        <PhoneIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            maxWidth: '80vw',
            width: '250px', // Largeur du menu
          },
        }}
      >
        <MenuItem>
          <Typography variant="body1">Pour nous contacter :</Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="h6" to="tel:0675227522">
            <strong>06 75 22 75 22</strong>
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default ContactFloatingButton;
