/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import api from '../../axiosConfig';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';


const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 49.524741,
  lng: 0.835161,
};

function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setResponseMessage('');

    try {
      const response = await api.post('/contact', formData);
      setResponseMessage(response.data.message);
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        setErrors(['Une erreur est survenue.']);
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
        Contactez-nous
      </Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2">
          Coordonnées
        </Typography>
        <Typography>Adresse: Epinay Sur Duclair, 76480, Normandie</Typography>
        <Typography>Téléphone: 06 75 22 75 22</Typography>
        <Typography>Email: jerome@jcpcreation.com</Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2">
          Suivez-nous sur nos réseaux sociaux
        </Typography>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
  <Typography>
    <a
      href="https://www.facebook.com/vosprojets"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'inherit' }} // Assure que la couleur du lien est héritée et ne change pas
    >
      <FacebookIcon fontSize="large" style={{ color: '#4267B2' }} />
    </a>
  </Typography>
  <Typography>
    <a
      href="https://www.instagram.com"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'inherit' }} // Assure que la couleur du lien est héritée et ne change pas
    >
      <InstagramIcon fontSize="large" style={{ color: '#ffffff' }} />
    </a>
  </Typography>
</Box>
</Box>
      {responseMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {responseMessage}
        </Alert>
      )}
      {errors.length > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nom"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
          sx={{
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#4a8b6c",
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#4a8b6c",
            "&.Mui-focused": {
              color: "#4a8b6c",
              fontWeight: "bold",
            },
          },
        },
      }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
          sx={{
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#4a8b6c",
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#4a8b6c",
            "&.Mui-focused": {
              color: "#4a8b6c",
              fontWeight: "bold",
            },
          },
        },
      }}
        />
        <TextField
          fullWidth
          label="Téléphone"
          name="phone"
          type="phone"
          value={formData.phone}
          onChange={handleChange}
          margin="normal"
          required
          sx={{
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#4a8b6c",
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#4a8b6c",
            "&.Mui-focused": {
              color: "#4a8b6c",
              fontWeight: "bold",
            },
          },
        },
      }}
              />
        <TextField
          fullWidth
          label="Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          margin="normal"
          required
          multiline
          rows={4}
          sx={{
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#4a8b6c",
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#4a8b6c",
            "&.Mui-focused": {
              color: "#4a8b6c",
              fontWeight: "bold",
            },
          },
        },
      }}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
    mt: 2,
    backgroundColor: '#4a8b6c',
    '&:hover': {
      backgroundColor: '#36724f', // Couleur de fond au survol
    },
  }}
        >
          Envoyer
        </Button>
      </form>
      <Box sx={{ mt: 5 }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
            <Marker />
          </GoogleMap>
        </LoadScript>
      </Box>
    </Box>
  );
}

export default ContactPage;
