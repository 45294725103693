import { useState } from 'react';
import {
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import logo from '../../logo.png'; // Assurez-vous de mettre le chemin correct vers votre logo
import PrestationsMenu from '../PrestationsMenu';
import './Header.scss';

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="header">
 <Box
      sx={{
        width: '100%',
        height: 40,
        bgcolor: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
      }}
    >
      <Typography
        variant="h7"
        component="div"
        sx={{ color: 'white', marginRight: 3, fontSize: isMobile ? '0.7rem' : '1rem' }}
      >
        Découvrez aussi :{' '}
      </Typography>
      <Button
        className="btn-custom"
        href="/prestation/12"
        sx={{
          width: 150,
          textTransform: 'capitalize',
          fontSize: isMobile ? '0.3rem' : '0.875rem',
        }}
      >
        Décapage par aérogommage
      </Button>
      <Button
        className="btn-station"
        href="/prestation/10"
        sx={{
          width: 150,
          marginLeft: 2,
          textTransform: 'capitalize',
          fontSize: isMobile ? '0.3rem' : '0.875rem',
        }}
      >
        Assainissement micro-station
      </Button>
        <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  }}
>
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    sx={{
      color: 'white',
      // Ajustez ici pour des styles spécifiques au mobile
      ...(isMobile && {
        flexDirection: 'column',
        '& .MuiSvgIcon-root': { fontSize: '24px' }, // Ajuste la taille de l'icône en mode mobile
      }),
    }}
  >
    <a href="tel:0675227522" style={{ color: 'inherit', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
      <PhoneIcon />
      {!isMobile && <Typography sx={{ ml: 1 }}>06 75 22 75 22</Typography>}
    </a>
  </Stack>
</Box>
<Box sx={{ display: 'flex' }}>
  <a href="mailto:jerome@jcpcreation.com" className="icon-hover" style={{ color: 'white', marginRight: 8 }}>
    <MailOutlineIcon />
  </a>
  <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="icon-hover" style={{ color: 'white', marginRight: 8 }}>
    <InstagramIcon />
  </a>
  <a href="https://www.facebook.com/vosprojets" target="_blank" rel="noopener noreferrer" className="icon-hover" style={{ color: 'white' }}>
    <FacebookIcon />
  </a>
</Box>
      </Box>
      <Toolbar sx={{ justifyContent: 'center', width: '80%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Link to="/">
            <img src={logo} alt="JCP Création Logo" style={{ height: 150 }} />
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            gap: 2,
            marginLeft: 'auto',
            color: 'white',
          }}
        >
          <Button color="inherit" href="/">
            Accueil
          </Button>
          <PrestationsMenu />
          <Button color="inherit" component={Link} to="/realisations">
            Nos réalisations
          </Button>
          <Button color="inherit" component={Link} to="/contact">
            Contact
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose} component={Link} to="/">
              Accueil
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <PrestationsMenu />
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/realisations"
            >
              Nos réalisations
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/contact">
              Contact
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </div>
  );
}

export default Header;
