import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        backgroundColor: '#8ca396',
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        bottom: 0,
        width: '100%',
      }}
    >
      <Typography variant="body2">
        &copy; 2024 JCP Creation. Tous droits réservés.
      </Typography>
      <Typography variant="body2">
      Réalisé par{' '}
      <a
        href="https://jason-pretavoine.fr"
        style={{ color: '#fff', textDecoration: 'underline' }}
      >
        Jason Pretavoine
      </a>
    </Typography>
    </Box>
  );
}

export default Footer;
